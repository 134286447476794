import { Notification } from "@ftdr/blueprint-components-react"
import React from "react"

class ErrorPage extends React.Component {
	render() {
		return (
			<div className="container mx-auto mt-7">
				<Notification
					variant="floating"
					status="error"
					showStatusLabel={false}
					matchColor={true}
					showCloseButton={false}
					size="large"
				>
					{this.props.children || "There was an error loading the page."}
				</Notification>
			</div>
		)
	}
}

export default ErrorPage
