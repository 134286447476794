import React from "react"
import { ProgressIndicator } from "@ftdr/blueprint-components-react"

export default function Loading() {
	return (
		<div className="flex items-center justify-center fixed top-0 left-0 bottom-0 right-0">
			<ProgressIndicator variant="circular" color="primary" size="large" />
		</div>
	)
}
