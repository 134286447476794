import React, { useEffect, useState } from "react"
import { FeatureTogglesContext } from "./Context"
import * as optimizelyReactSDK from "@optimizely/react-sdk"
import ConfigService from "../../services/config"
import { FEATURE_TOGGLE_DEFAULT_VALUE, FEATURE_TOGGLE_PROVIDER_OPTIMIZELY, FeatureToggleNamesByProvider } from "./constants"
import Loading from "../../pages/Loading"

const FeatureTogglesProvider = ({ children }) => {
	const provider = ConfigService.config.featureTogglesProvider
	const config = ConfigService.config[provider]

	switch (provider) {
		case FEATURE_TOGGLE_PROVIDER_OPTIMIZELY: {
			return OptimizelyFeatureTogglesProvider({ config, children })
		}
	}

	return <>{children}</>
}

const OptimizelyFeatureTogglesProvider = ({ config, children }) => {
	const [optimizelyInstance, setOptimizelyInstance] = useState()
	const [isReady, setReady] = useState(false)

	useEffect(() => {
		const optimizelyInstance = optimizelyReactSDK.createInstance(config)
		optimizelyReactSDK.setLogLevel("warn")
		setOptimizelyInstance(optimizelyInstance)
		optimizelyInstance.onReady().then(() => {
			setReady(true)
		})
	}, [])

	const getFeatureToggle = (key, featureToggleName, attributes) => {
		if (!isReady || !optimizelyInstance) {
			return FEATURE_TOGGLE_DEFAULT_VALUE
		}

		const mappedFeatureToggleName =
			FeatureToggleNamesByProvider[FEATURE_TOGGLE_PROVIDER_OPTIMIZELY][featureToggleName] ?? featureToggleName

		return optimizelyInstance.decide(mappedFeatureToggleName, undefined, key, attributes).variationKey
	}

	const isFeatureToggleOn = (key, featureToggleName, attributes) => {
		return getFeatureToggle(key, featureToggleName, attributes) === "on"
	}

	const getFeatureToggleList = () => {
		return Object.keys(optimizelyInstance.getOptimizelyConfig().featuresMap).sort()
	}

	if (!isReady) {
		return <Loading />
	}

	return (
		<optimizelyReactSDK.OptimizelyProvider optimizely={optimizelyInstance}>
			<FeatureTogglesContext.Provider
				value={{
					provider: FEATURE_TOGGLE_PROVIDER_OPTIMIZELY,
					isReady,
					getFeatureToggle,
					isFeatureToggleOn,
					getFeatureToggleList,
				}}
			>
				{children}
			</FeatureTogglesContext.Provider>
		</optimizelyReactSDK.OptimizelyProvider>
	)
}

export default FeatureTogglesProvider
