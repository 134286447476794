class ContentItem {
	content = null
	isLoading = false
	hasLoaded = false
}

ContentItem.prototype[Symbol.toStringTag] = "ContentItem"

export default {
	header: new ContentItem(),
	footer: new ContentItem(),
	paymentTermsAndConditions: new ContentItem(),
	requestServiceTermsAndConditions: new ContentItem(),
	tsfTermsAndConditions: new ContentItem(),
	maidServiceTermsAndConditions: new ContentItem(),
	pestServiceTermsAndConditions: new ContentItem(),
	covid19Statement: new ContentItem(),
	covid19StatementContractors: new ContentItem(),
	whatsCoveredPopupConfigs: [],

	userAlerts: [],
}
