import { useEffect } from "react"
import ConfigService from "../services/config"
import { getSiteName } from "../utils"

const TealiumLoader = ({ children }) => {
	useEffect(() => {
		const { tealium } = ConfigService.config
		const tealiumEnv = tealium?.env
		//Initialize Tealium + utag + hotjar
		if (tealiumEnv && typeof document !== "undefined") {
			// NOTE: The flag below is used to override the Tealium GA tag.
			window.utag_cfg_ovrd = { noview: true }
			window.utag_data = {}
			;(function (a, b, c, d) {
				a = `https://tags.tiqcdn.com/utag/americanhomeshield/${getSiteName()}/${tealiumEnv}/utag.js`
				b = document
				c = "script"
				d = b.createElement(c)
				d.src = a
				d.type = "text/java" + c
				d.async = true
				a = b.getElementsByTagName(c)[0]
				a.parentNode.insertBefore(d, a)
			})()
		}
	}, [])

	return <>{children}</>
}

export default TealiumLoader
