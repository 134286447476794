import React from "react"
import { connect } from "react-redux"
import Alert from "./Alert"
import UserAlert from "./UserAlert"

const Alerts = ({ alerts, userAlerts, isAboveHeaderOnDesktop, isAboveHeaderOnMobile }) => {
	const userAlertsAboveHeader = []
	const userAlertsInPage = []

	for (const userAlert of userAlerts) {
		if (userAlert.showAboveHeader) {
			userAlertsAboveHeader.push(userAlert)
		} else {
			userAlertsInPage.push(userAlert)
		}
	}

	const renderUserAlerts = (userAlerts, isAboveHeader) => {
		return userAlerts.map((userAlert, i) => <UserAlert key={i} userAlert={userAlert} isAboveHeader={isAboveHeader} />)
	}

	return (
		<div id="alerts">
			{isAboveHeaderOnDesktop && <div className="hidden md:block">{renderUserAlerts(userAlertsAboveHeader, true)}</div>}
			{isAboveHeaderOnMobile && <div className="md:hidden">{renderUserAlerts(userAlertsAboveHeader, false)}</div>}

			{!isAboveHeaderOnDesktop && !isAboveHeaderOnMobile && (
				<>
					{alerts.map((alert) => (
						<Alert key={alert.id} alert={alert} />
					))}

					{renderUserAlerts(userAlertsInPage, false)}
				</>
			)}
		</div>
	)
}

const mapStateToProps = (state) => ({
	alerts: state.alert.list,
	userAlerts: state.zesty.userAlerts,
})

export default connect(mapStateToProps)(Alerts)
