import GenericService from "./generic"

const SERVICE_NAME = "payment"

class PaymentService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
		this.useCheckValidateContractAccessErrorInResponse()
	}

	fetchPaymentInfo(contractID) {
		return this.axios.get(`/my-account/${contractID}/payment-info`)
	}

	makePayment(contractID, payload) {
		return this.axios.post(`/my-account/${contractID}/one-time-payment`, payload)
	}
}

export default new PaymentService()
