import { isValidPhoneNumber, isValidEmailAddress } from "../../utils"

export default {
	isLoggedIn: false,
	user: null,
	profile: null,
	profileError: false,
	saving: false,
	passwordIsSaving: false,
	profileIsSaving: false,

	getUserFullName() {
		if (this.profile && this.profile.firstName && this.profile.lastName) {
			return `${this.profile.firstName} ${this.profile.lastName}`.trim()
		} else {
			return null
		}
	},

	hasCompleteProfile() {
		return (
			this.profile &&
			this.profile.firstName.trim() !== "" &&
			this.profile.lastName.trim() !== "" &&
			isValidPhoneNumber(this.profile.phoneNumber) &&
			isValidEmailAddress(this.profile.emailAddress)
		)
	},

	getUserFirstName() {
		return this.profile?.firstName
	},

	getUserLastName() {
		return this.profile?.lastName
	},
}
