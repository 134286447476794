import { ActionsBuilder } from "../utils"
import namespace from "./namespace"

const actionsBuilder = new ActionsBuilder(namespace)

export const AppActions = {
	toggleMobileAddressMenuOpen: actionsBuilder.createAction("toggleMobileAddressMenuOpen", (state) => {
		state[namespace].isMobileAddressMenuOpen = !Boolean(state[namespace].isMobileAddressMenuOpen)
	}),

	toggleMobileNavMenuOpen: actionsBuilder.createAction("toggleMobileNavMenuOpen", (state) => {
		state[namespace].isMobileNavMenuOpen = !Boolean(state[namespace].isMobileNavMenuOpen)
	}),
}

export const actions = actionsBuilder.exportActions()
