import axios from "axios"
import { extend } from "../utils"
import SentryLibrary from "../lib/sentry"

export default class ConfigService {
	static config = null

	static async loadConfig() {
		try {
			const response = await axios.get("/client-configs")
			const json = await response.data

			if (json && Array.isArray(json)) {
				this.config = extend({}, ...json)
				//console.log("Successfully loaded config")
			} else {
				throw new Error("Config data should be a JSON array of objects")
			}
		} catch (e) {
			// If we cannot load config, at least load Sentry.io so we can track this error
			SentryLibrary.initialize()

			if (e.response) {
				console.error(`[Config Error] Not OK response: ${e.response.status} ${e.response.statusText}`)
				console.error(`[Config Error] Path: ${e.request.responseURL}`)
			} else if (e.request) {
				console.error(`[Config Error] No response:`, e.request.responseURL)
			} else {
				console.error(`[Config Error] Uncaught exception: ${e.message}`)
			}
		}
	}
}
