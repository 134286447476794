var ALERT_ID_COUNTER = 0

export class Alert {
	constructor(options) {
		if (options && options instanceof Alert) {
			this.id = options.id
			this.options = options.options
		} else if (options?.id || options?.options) {
			this.id = options.id || `alert_${++ALERT_ID_COUNTER}`
			this.options = this.parseOptions(options.options)
		} else {
			this.id = `alert_${++ALERT_ID_COUNTER}`
			this.options = this.parseOptions(options)
		}
	}

	parseOptions(options) {
		this.options = Object.assign(
			{
				useBDS: false,
				type: "warning", // Non-BDS = warning, info, success. BDS = error, warning, info, success
				message: "",
				matchColor: false, // BDS Notification matchColor prop
				variant: undefined, // BDS Notification variant prop
				closeButton: false,
				closeButtonStyle: "x",
				button: null,
				tooltip: null,
				onButtonClick: null,
				onCloseAlert: null,
				onTooltipClick: null,
				persist: false,
				messageData: undefined,
			},
			options
		)
		if (this.options.closeButton && this.options.closeButtonStyle === "dismiss") {
			this.options.closeButton = false

			if (!this.options.button) {
				this.options.button = []
			} else if (!Array.isArray(this.options.button)) {
				this.options.button = [this.options.button]
			}

			this.options.button.push({
				text: "ALERTS.BUTTON_DISMISS",
				link: "#",
				isAlternate: true,
				isCloseButton: true,
			})
		}
		return this.options
	}
}

Alert.prototype[Symbol.toStringTag] = "Alert"

export default {
	list: [],
}
