import { useEffect, useState } from "react"
import SentryLibrary from "../lib/sentry"
import ZestyService from "../services/zesty"
import Loading from "../pages/Loading"

const SentryLoader = ({ children }) => {
	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		const abortController = new AbortController()

		ZestyService.getSentryIgnoreErrors({ signal: abortController.signal }).then(
			(data) => {
				if (abortController.signal.aborted) return

				try {
					const ignoreErrors = data.map((error) => new RegExp(error))
					SentryLibrary.initialize(ignoreErrors)
				} catch (error) {
					SentryLibrary.initialize()
					console.error("Error creating regex for Sentry Ignore Errors: ", error.message)
				}

				setLoading(false)
			},
			() => {
				SentryLibrary.initialize()
				console.error("Error getting Sentry Ignore Errors from Zesty")
				setLoading(false)
			}
		)

		return () => abortController.abort()
	}, [])

	return isLoading ? <Loading /> : <>{children}</>
}

export default SentryLoader
