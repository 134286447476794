import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import contractNamespace from "../contract/namespace"
import { suppressPromo } from "../user/actions"
import PromoService from "../../services/promo"
import { Alert } from "./state"

const actionsBuilder = new ActionsBuilder(namespace)

export const addAlert = actionsBuilder.createAction("addAlert", (state, alert) => {
	// Note: Cannot modify existing array (push). Have to return a new array (concat)
	state[namespace].list = state[namespace].list.concat([alert])
})

// Accepts % at the start and end of string for multiple removal
export const removeAlert = actionsBuilder.createAction("removeAlert", (state, id) => {
	// Note: Cannot modify existing array (splice). Have to return a new array (filter)
	state[namespace].list = state[namespace].list.filter((x) => {
		if (id.slice(0, 1) === "%" && id.slice(-1) === "%") {
			return !x.id.includes(id.slice(1, id.length - 1))
		}

		return x.id !== id
	})
})

export const removeAllAlerts = actionsBuilder.createAction("removeAllAlerts", (state) => {
	state[namespace].list = state[namespace].list.filter((e) => e.options.persist)
})

export const updateAlert = actionsBuilder.createAction("updateAlert", (state, { id, options }) => {
	const alert = state[namespace].list.find((alert) => alert.id === id)
	if (alert) {
		Object.assign(alert.options, options)
		state[namespace].list = state[namespace].list.slice()
	}
})

export const AlertActions = {
	createAlert: (options) => (dispatch) => {
		const alert = new Alert(options)

		dispatch(addAlert(alert))

		return alert.id
	},
	removeAlert: (id) => (dispatch) => {
		dispatch(removeAlert(id))
	},
	updateAlert: (id, options) => (dispatch) => dispatch(updateAlert({ id, options })),
	removeAllAlerts: () => (dispatch, getState) => {
		const state = getState()
		const { fetchPromosLoadingPromise: loadingPromise } = state[namespace]

		if (loadingPromise) {
			loadingPromise.cancel()
		}

		dispatch(removeAllAlerts())
	},
	suppressPromo: (promo) => (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]

		if (current) {
			const contractID = current.contractID
			try {
				PromoService.suppressPromo(contractID, promo)
			} catch (e) {}

			dispatch(suppressPromo({ promo, contractID }))
		}
	},
}

export const actions = actionsBuilder.exportActions()
