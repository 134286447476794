import { siteNameToTenant } from "../../utils"
import { MyAccountService } from "./index"

class MyAccountPaymentService extends MyAccountService {
	async getPastDueInvoices(contractID, config = undefined) {
		const client = await this.getClient(config)

		const response = await client.getContractPastDueInvoices({
			contractID,
			tenant: siteNameToTenant(),
		})

		return response.data
	}

	async getPastDueAmounts(contractID, config = undefined) {
		const client = await this.getClient(config)

		const response = await client.getContractPastDueAmounts({
			contractID,
			tenant: siteNameToTenant(),
		})

		return response.data
	}

	async getPaymentHistory(contractID, config = undefined) {
		const client = await this.getClient(config)

		const response = await client.getContractPaymentHistory({
			contractID,
			tenant: siteNameToTenant(),
		})

		return response.data
	}

	async getPaymentCredits(contractID, config = undefined) {
		const client = await this.getClient(config)

		const response = await client.getContractPaymentCredits({
			contractID,
			tenant: siteNameToTenant(),
		})

		return response.data
	}
}

export default new MyAccountPaymentService()
