import { createContext } from "react"
import { FEATURE_TOGGLE_DEFAULT_VALUE } from "./constants"

export const defaultContext = {
	provider: undefined,
	isReady: false,
	getFeatureToggle: (key, featureToggleName, attributes) => FEATURE_TOGGLE_DEFAULT_VALUE,
	isFeatureToggleOn: (key, featureToggleName, attributes) => false,
	getFeatureToggleList: () => [],
}

export const FeatureTogglesContext = createContext(defaultContext)

FeatureTogglesContext.displayName = "FeatureTogglesContext"
