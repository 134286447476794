import React from "react"
import { connect } from "react-redux"
import Alerts from "../../components/Alerts"
import Page from "../../components/Page"
import ErrorPage from "../../pages/Error"
import Loading from "../../pages/Loading"
import AuthService from "../../services/auth"
import { ContractActions } from "../../store/contract/actions"
import { UserActions } from "../../store/user/actions"
import PageRoute from "./PageRoute"

const UserPageRoute = ({ isLoggedIn, autoLoginUser, hasUserAlertBlock, route }) => {
	const [startedLoggingIn, setStartedLoggingIn] = React.useState(false)
	const [errorLoggingIn, setErrorLoggingIn] = React.useState(false)
	const [doneLoggingIn, setDoneLoggingIn] = React.useState(false)

	React.useEffect(() => {
		if (!isLoggedIn && !hasUserAlertBlock && !startedLoggingIn) {
			setStartedLoggingIn(true)

			autoLoginUser()
				.then(() => setDoneLoggingIn(true))
				.catch((e) => {
					console.error(e)
					setErrorLoggingIn(true)
				})
		}
	}, [isLoggedIn, hasUserAlertBlock, startedLoggingIn])

	if (hasUserAlertBlock) {
		return (
			<Page>
				<div className="container mx-auto">
					<Alerts />
				</div>
			</Page>
		)
	} else if (errorLoggingIn) {
		return (
			<Page>
				<div className="container mx-auto">
					<Alerts />
				</div>

				<ErrorPage />
			</Page>
		)
	} else if ((!isLoggedIn && !startedLoggingIn) || (startedLoggingIn && !doneLoggingIn)) {
		return <Loading />
	} else {
		return <PageRoute route={route} />
	}
}

const mapStateToProps = (state) => ({
	isLoggedIn: state.user.isLoggedIn,
	hasUserAlertBlock: state.zesty.userAlerts.some((userAlert) => userAlert.showOnLogin),
})

const mapDispatchToProps = (dispatch) => ({
	autoLoginUser: async () => {
		const user = await AuthService.getClient().getUser()

		if (user) {
			if (user.expires_in > 0) {
				const isLoggedIn = await dispatch(UserActions.login())

				if (isLoggedIn) {
					const fetchedContracts = await dispatch(ContractActions.fetchContracts())

					if (!fetchedContracts) {
						throw new Error("Could not fetch contracts")
					}
				}
			} else {
				AuthService.getClient().removeUser()
			}
		}
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPageRoute)
