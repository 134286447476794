import moment from "moment"
import GenericService from "./generic"

const SERVICE_NAME = "zesty"

class ZestyService extends GenericService {
	constructor() {
		super(SERVICE_NAME)
	}

	_getContent(contentPath, config = {}) {
		return this.axios.get(contentPath, { responseType: "text", ...config })
	}

	_getJSON(contentPath, config = {}) {
		return this.axios.get(contentPath, { responseType: "json", ...config })
	}

	termsAndConditionsContentKeyMap = {
		paymentTermsAndConditions: (siteName) => `/ajax/${siteName}_terms_and_conditions`,
		requestServiceTermsAndConditions: (siteName) => `/ajax/${siteName}_request_service_terms_and_conditions`,
		tsfTermsAndConditions: (siteName) => `/ajax/${siteName}_tsf_terms_and_conditions`,
		maidServiceTermsAndConditions: (siteName) => `/ajax/${siteName}_maid_service_terms_and_conditions`,
		pestServiceTermsAndConditions: (siteName) => `/ajax/${siteName}_pest_service_terms_and_conditions`,
		covid19Statement: (siteName) => `/ajax/${siteName}_covid_19_statement`,
		covid19StatementContractors: (siteName) => `/ajax/${siteName}_covid_19_statement_contractors`,
	}

	async getTermsAndConditions(siteName, contentKey) {
		if (contentKey in this.termsAndConditionsContentKeyMap) {
			return await this._getContent(this.termsAndConditionsContentKeyMap[contentKey](siteName))
		} else {
			throw this.handleError(new Error(`Unknown TermsAndConditions content key: ${contentKey}`))
		}
	}

	getTranslations(environment) {
		return this._getJSON(`/translations.json`, {
			params: { environment },
		})
	}

	async getWhatsCoveredPopupConfigs() {
		const whatsCoveredPopupConfigs = await this._getJSON("/-/custom/whats_covered_popup_configs.json")

		whatsCoveredPopupConfigs.forEach((config) => {
			// Fix some config fields from zesty
			config.serviceItemIDs = config.serviceItemIDs.split(",").map((serviceItemID) => serviceItemID.trim())
			config.columns.forEach((column) => {
				column.content.forEach((content) => {
					if (content.listItems) {
						content.listItems = content.listItems.split("\n")
					}
				})
			})
		})

		return whatsCoveredPopupConfigs
	}

	async getServiceItemSymptomVideos() {
		const response = await this._getJSON("/-/custom/service_item_symptom_videos.json")

		return response.data
	}

	async getServiceItemsImage() {
		const response = await this._getJSON(`/service_items_image.json`)
		return response.data
	}

	async getUserAlerts(siteName, environment) {
		const response = await this._getJSON("/-/custom/user_alerts.json", {
			params: {
				brand: siteName,
				env: environment,
			},
		})

		return response.data.filter((userAlert) => {
			if (userAlert.startDate && moment().isBefore(userAlert.startDate)) {
				return false
			}
			if (userAlert.stopDate && moment().isAfter(userAlert.stopDate)) {
				return false
			}
			return true
		})
	}

	async getTermsAndConditionsConfig(program, siteName) {
		const response = await this._getJSON("/terms_and_conditions_config.json", {
			params: { program, brand: siteName },
		})

		if (!response.data?.length) {
			return null
		}

		const config = response.data[0]
		config.effectiveDate = moment(config.effectiveDate).unix()
		return config
	}

	getCancelationReasons() {
		return this._getJSON("/cancelation_reasons.json")
	}

	getSampleContractLink(state) {
		return this._getJSON("/sample_contract_pdf.json", {
			params: { state: state },
		}).then((res) => res.data.link)
	}

	getPlanLimitationsAndExclusions() {
		return this._getJSON("/limitations_and_exclusions.json")
	}

	async getServiceItemsExclusions() {
		const response = await this._getJSON(`/service_items_exclusions.json`)
		return response.data
	}

	async getSentryIgnoreErrors(config = {}) {
		const response = await this._getJSON("/sentry_ignore_errors.json", config)
		return response.data
	}
}

export default new ZestyService()
