import React from "react"
import Alerts from "./Alerts"

export default function PageContent({ children, className }) {
	return (
		<div id="page-content" className={className}>
			<Alerts isAboveHeaderOnMobile={true} />

			{children}
		</div>
	)
}
