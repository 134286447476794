import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import ConfigLoader from "./components/ConfigLoader"
import QuiqChatLoader from "./components/QuiqChatLoader"
import SentryLoader from "./components/SentryLoader"
import TealiumLoader from "./components/TealiumLoader"
import UserStateManager from "./components/UserStateManager"
import FeatureTogglesProvider from "./contexts/FeatureToggles/Provider"
import store from "./store"
import App from "./App"

// Note: Order of these changes which CSS gets priority during conflicts. The 2nd one will override the 1st one in the event of a conflict.
import "./assets/css/tailwind.min.css"

render(
	<ConfigLoader>
		<SentryLoader>
			<Provider store={store}>
				<UserStateManager>
					<BrowserRouter>
						<FeatureTogglesProvider>
							<QuiqChatLoader>
								<TealiumLoader>
									<App />
								</TealiumLoader>
							</QuiqChatLoader>
						</FeatureTogglesProvider>
					</BrowserRouter>
				</UserStateManager>
			</Provider>
		</SentryLoader>
	</ConfigLoader>,
	document.getElementById("root")
)
