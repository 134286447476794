import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import QuiqChatLibrary from "../../lib/quiqchat"

const actionsBuilder = new ActionsBuilder(namespace)

export const setNewContactPoint = actionsBuilder.createAction("setNewContactPoint", (state, contactPoint) => {
	state[namespace].currentContactPoint = contactPoint
})

export const setIsAgentAvailable = actionsBuilder.createAction("setIsAgentAvailable", (state, isAgentAvailable) => {
	state[namespace].isAgentAvailable = isAgentAvailable
})

export const setChatLoading = actionsBuilder.createAction("setChatLoading", (state, abortController) => {
	state[namespace].abortController?.abort()
	state[namespace].abortController = abortController
})

export const QuiqChatActions = {
	loadChat: (params) => async (dispatch, getState) => {
		const state = getState()
		const { currentContactPoint } = state[namespace]
		dispatch(setIsAgentAvailable(false))

		const abortController = new AbortController()
		dispatch(setChatLoading(abortController))

		if (!QuiqChatLibrary.loading) {
			let chatClient = null
			try {
				if (currentContactPoint === "") {
					chatClient = await QuiqChatLibrary.initialize(params.contactPoint, params.options)
				} else {
					chatClient = await QuiqChatLibrary.reinitialize(params.contactPoint, params.options)
				}
			} catch (e) {
				//TODO handle this better
				console.warn(e)
			}

			if (abortController.signal.aborted) {
				return
			}

			if (chatClient !== null) {
				dispatch(setNewContactPoint(params.contactPoint))

				//setAgentAvailablity
				chatClient.on("agentAvailabilityDidChange", ({ available }) => {
					//	console.log(available)
					dispatch(setIsAgentAvailable(available))
				})
			}

			let active = false

			if (params.populateWelcomeForm) {
				active = await QuiqChatLibrary.getChatStatus()

				if (abortController.signal.aborted) {
					return
				}

				//populateWelcomeForm
				if (!active) {
					let welcomeForm = {
						firstName: state.user.getUserFirstName(),
						lastName: state.user.getUserLastName(),
						contractID: state.contract.current?.contractID,
						address: state.contract.current ? state.contract.getFullStreetAddress(state.contract.current) : undefined,
						...params.welcomeForm,
					}

					QuiqChatLibrary.populateWelcomeForm(
						welcomeForm.firstName,
						welcomeForm.lastName,
						welcomeForm.contractID,
						welcomeForm.address,
						welcomeForm.dispatchID
					)
				}
			}
		}
	},
	unloadChat: (clearSession) => async (dispatch) => {
		try {
			await QuiqChatLibrary.unload(clearSession)
			dispatch(setNewContactPoint(""))
		} catch (e) {
			console.warn(e)
		}
	},
}

export const actions = actionsBuilder.exportActions()
