import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import * as Sentry from "@sentry/react"
import { combineReducers, createReducer } from "./utils"

// TODO: Change composeWithDevTools import to this for production
//import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import alert from "./alert"
import app from "./app"
import contact from "./contact"
import contract from "./contract"
import quiqchat from "./quiqchat"
import payment from "./payment"
import requestService from "./requestService"
import user from "./user"
import zesty from "./zesty"

const config = combineReducers({
	alert,
	app,
	contact,
	contract,
	quiqchat,
	payment,
	requestService,
	user,
	zesty,
})

const reducer = createReducer(config)

const composeEnhancers = composeWithDevTools({
	features: {
		pause: true, // start/pause recording of dispatched actions
		lock: true, // lock/unlock dispatching actions and side effects
		persist: true, // persist states on page reloading
		export: true, // export history of actions in a file
		import: "custom", // import history of actions from a file
		jump: true, // jump back and forth (time travelling)
		skip: true, // skip (cancel) actions
		reorder: true, // drag and drop actions in the history list
		dispatch: true, // dispatch custom actions or action creators
		test: true, // generate tests for the selected actions
	},
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	attachReduxState: false,
	actionTransformer: (action) => {
		// TODO: Hide actions from log by returning null instead

		// TODO: Return a modified action to hide sensitive info from log

		return action
	},
	stateTransformer: (state) => {
		// TODO: Hide this state from log by returning null instead

		// TODO: Return a modified state to hide sensitive info from log

		return state
	},
	configureScopeWithState: (scope, state) => {
		// TODO: Tag the sentry scope logs based on state
		//scope.setTag("user.isLoggedIn", state.user.isLoggedIn)
	},
})

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer))

export default store
