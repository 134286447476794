import React from "react"
import classNames from "classnames"
import { Alert as AlertModel } from "../store/alert/state"
import { AppContext, Button, Notification } from "@ftdr/blueprint-components-react"
import { presetButtonPropsAlertPrimary, presetButtonPropsAlertSecondary } from "../utils"

/*
 * Props:
 * - alert: Object to represent the alert, should match up options to `AlertModel` in the alert store, or it can be an `AlertModel` instance
 */

class Alert extends React.Component {
	static contextType = AppContext

	constructor(props) {
		super(props)

		this.alert = new AlertModel(this.props.alert)

		this.state = {
			isClosed: false,
			isTooltipOpen: false,
		}

		this.handleCloseButton = this.handleCloseButton.bind(this)
		this.handleTooltipClick = this.handleTooltipClick.bind(this)
	}

	hasCloseButtonX() {
		return this.alert.options.closeButton && this.alert.options.closeButtonStyle === "x"
	}

	renderCloseButtonX() {
		if (!this.hasCloseButtonX()) {
			return null
		}

		return <a className="close-button" onClick={this.handleCloseButton}></a>
	}

	handleCloseButton(e) {
		e.preventDefault()

		if (typeof this.alert.options.onCloseAlert === "function") {
			this.alert.options.onCloseAlert(e)
			this.setState({ isClosed: true })
		} else {
			this.setState({ isClosed: true })
		}
	}

	renderTooltip() {
		if (!this.alert.options.tooltip) {
			return null
		}

		if (!this.state.isTooltipOpen) {
			return (
				<a href="#" className="alert-tooltip-icon" onClick={this.handleTooltipClick}>
					?
				</a>
			)
		}

		return (
			<span className="alert-tooltip-popup">
				<span
					className="alert-tooltip-message"
					dangerouslySetInnerHTML={{
						__html: this.context.appSettings.localizedText(this.alert.options.tooltip),
					}}
				></span>
			</span>
		)
	}

	handleTooltipClick(e) {
		e.preventDefault()

		this.setState({ isTooltipOpen: true })

		if (typeof this.alert.options.onTooltipClick === "function") {
			this.alert.options.onTooltipClick(e)
		}
	}

	renderButtons() {
		if (!this.alert.options.button) {
			return null
		}

		let buttons = this.alert.options.button

		if (Array.isArray(buttons)) {
			if (!buttons.length) {
				return null
			}
		} else {
			buttons = [buttons]
		}

		return <div className="flex flex-wrap gap-2 ml-5">{buttons.map((button, i) => this.renderButton(button, i))}</div>
	}

	renderButton(button, i) {
		const handleButtonClick = (e) => {
			if (button.isCloseButton) {
				this.handleCloseButton(e)
			} else {
				if (typeof this.alert.options.onButtonClick === "function") {
					this.alert.options.onButtonClick(e)
				}

				if (typeof button.onClick === "function") {
					button.onClick(e)
				}
			}
		}

		const presetButtonProps = button.isAlternate ? presetButtonPropsAlertSecondary : presetButtonPropsAlertPrimary

		return (
			<Button
				key={i}
				label={this.context.appSettings.localizedText(button.text)}
				size="small"
				color={this.alert.options.type === "info" ? "interactive" : this.alert.options.type}
				href={button.link || undefined}
				target={button.link ? button.target : undefined}
				rel={button.link && button.target ? "noopener noreferrer" : undefined}
				onClick={handleButtonClick}
				className="capitalize"
				{...presetButtonProps()}
			/>
		)
	}

	renderButtonsOld() {
		if (!this.alert.options.button) {
			return null
		}

		let buttons = this.alert.options.button

		if (Array.isArray(buttons)) {
			if (!buttons.length) {
				return null
			}
		} else {
			buttons = [buttons]
		}

		return <div className="buttons">{buttons.map((button, i) => this.renderButtonOld(button, i))}</div>
	}

	renderButtonOld(button, key) {
		const handleButtonClick = (e) => {
			if (button.isCloseButton) {
				this.handleCloseButton(e)
			} else {
				if (typeof this.alert.options.onButtonClick === "function") {
					this.alert.options.onButtonClick(e)
				}

				if (typeof button.onClick === "function") {
					button.onClick(e)
				}
			}
		}

		let attributes = {}
		if (button.target) {
			attributes.rel = "noopener noreferrer"
		}

		return (
			<a
				key={key}
				className={classNames("btn", button.className, { "btn-outlined": button.isAlternate })}
				href={button.link}
				target={button.target}
				{...attributes}
				dangerouslySetInnerHTML={{ __html: this.context.appSettings.localizedText(button.text) }}
				onClick={handleButtonClick}
			></a>
		)
	}

	render() {
		if (this.state.isClosed) {
			return null
		}

		const { type, message, messageData, useBDS } = this.alert.options

		return useBDS ? (
			<Notification
				id={this.alert.id}
				className={this.alert.options.variant !== "inline" ? "mb-5" : undefined}
				variant={this.alert.options.variant ?? "floating"}
				status={type}
				showStatusLabel={false}
				matchColor={this.alert.options.matchColor}
				showCloseButton={Boolean(this.alert.options.closeButton)}
				onClose={(e) => this.handleCloseButton(e)}
				actions={this.renderButtons()}
			>
				{typeof message === "string" ? (
					<span dangerouslySetInnerHTML={{ __html: this.context.appSettings.localizedText(message, messageData) }}></span>
				) : (
					message
				)}
			</Notification>
		) : (
			<div id={this.alert.id} className={`alert alert-${type}`}>
				<div className={classNames("alert-content", { "alert-content-with-close-button": this.hasCloseButtonX() })}>
					<span className="icon"></span>

					{this.renderCloseButtonX()}

					<div className="message">
						{typeof message === "string" ? (
							<span
								className="message-inner"
								dangerouslySetInnerHTML={{ __html: this.context.appSettings.localizedText(message, messageData) }}
							></span>
						) : (
							<span className="message-inner">{message}</span>
						)}

						{this.renderTooltip()}
					</div>

					{this.renderButtonsOld()}
				</div>
			</div>
		)
	}
}

export default Alert
