import GenericService from "./generic"

const SERVICE_NAME = "contact"

class ContactService extends GenericService {
	constructor() {
		super(SERVICE_NAME)

		this.useAccessTokenInAxios()
		this.useCheckValidateContractAccessErrorInResponse()
	}

	fetchContactInfo(contractID) {
		return this.axios.get(`/my-account/contact-info/${contractID}`, { params: { detailed: true } })
	}

	saveContactInfo(contractID, contactInfo) {
		return this.axios.post(`/my-account/contact-info/${contractID}`, contactInfo)
	}

	fetchEfulfillmentPreference(contractID) {
		return this.axios.get(`/my-account/efulfillment/${contractID}`)
	}

	saveEfulfillmentPreference(contractID, eFulfillmentEnabled) {
		return this.axios.put(`/my-account/efulfillment/${contractID}/enabled/${eFulfillmentEnabled}`)
	}
}

export default new ContactService()
