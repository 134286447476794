export default {
	canRequestService: false,
	canRequestServiceLoadingPromise: null,
	dwellingTypeCode: null,

	//contract download
	purchaseDate: null,
	canDownloadContract: false,

	// contract lookup
	sessionUUID: null,
	sessionExp: null,
	contract: null,
	isRequestCurrentContract: (state) =>
		state.user.isLoggedIn &&
		state.contract.current &&
		state.requestService.contract &&
		state.contract.current.contractID === state.requestService.contract.contractID,

	// pick items page
	serviceItemCategories: [],
	serviceItemCategoriesLoading: false,
	serviceItemCategoriesError: false,
	serviceItemCategoriesLoadingPromise: null,

	// items details page
	skipItemsDetail: false,
	serviceItemOptionsMap: {},
	selectedServiceItems: [],
	pricing: null,

	// contractor page
	scheduleOptions: null,
	contractorOptions: null,
	scheduleOption: null,
	contractorOption: null,

	// payment page
	storedPaymentMethods: null,
	defaultContactInfo: null,
	contactInfoPhoneRequired: false, // TODO: remove if BDS request flow is active

	// confirmation page
	confirmationDetails: null,
}
