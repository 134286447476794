import * as Sentry from "@sentry/react"
import { CaptureConsole } from "@sentry/integrations"
import ConfigService from "../services/config"
import { getEnvironmentFromURL } from "../utils"

export default class SentryLibrary {
	static initialized = false

	static initialize(ignoreErrors) {
		if (this.initialized) {
			return
		}

		this.initialized = true

		const env = ConfigService.config?.env ?? getEnvironmentFromURL()

		Sentry.init({
			dsn: "https://5c2a7b4aa8524fa08e640613f5b857c7@o525107.ingest.sentry.io/6065031",
			integrations: [
				new Sentry.BrowserTracing(),
				new CaptureConsole({
					levels: ["error"],
				}),
			],
			tracesSampleRate: 0,
			environment: env,
			release: process.env.REACT_APP_CI_COMMIT_SHORT_SHA,
			ignoreErrors,
		})
	}
}
