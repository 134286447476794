import React, { useEffect } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
	Button,
	IconWarningTriangleFull,
	IconTrash,
	ProgressIndicator,
	Text,
	useLocalizedText,
	Select,
	Dialog,
	Input,
} from "@ftdr/blueprint-components-react"
import { withRouter } from "react-router-dom"
import { ContractActions } from "../store/contract/actions"
import { useIsDesktop } from "../hooks"
import classNames from "classnames"
import { isSiteAHS } from "../utils"

const AddressBar = ({
	// redux props - state
	currentContract,
	contracts,
	requestServiceContract,
	isRequestCurrentContract,
	getContractNotifications,
	getFullStreetAddress,

	// redux props - dispatch
	setCurrentContract,
	removeContract,

	// withRouter
	history,
	location,
}) => {
	const isDesktop = useIsDesktop()
	const localizedText = useLocalizedText()
	const [selected, setSelected] = React.useState()
	const [removeProperty, setRemoveProperty] = React.useState(false)
	const [propertyToRemove, setPropertyToRemove] = React.useState()
	const [changeProperty, setChangeProperty] = React.useState(false)
	const [propertyToChange, setPropertyToChange] = React.useState()
	const [isRemovingProperty, setIsRemovingProperty] = React.useState(false)
	const ADD_PROPERTY_LABEL = localizedText("DASHBOARD_NAV.LINK_ADD_PROPERTY")

	useEffect(() => {
		if (currentContract) {
			setSelected({ value: currentContract.property.streetAddress + " " + currentContract.property.streetAddress2 ?? "" })
		}
	}, [currentContract])

	const otherContracts = () => {
		var options = []
		if (contracts) {
			options = contracts
				.filter((contract) => contract.contractID !== currentContract.contractID)
				.map((contract, i) => ({
					value: contract,
				}))
		}
		return [{ value: ADD_PROPERTY_LABEL }, ...options]
	}

	const onPropertySelect = (value) => {
		if (location.pathname.startsWith("/request-service") && !location.pathname.startsWith("/request-service/confirmation")) {
			setChangeProperty(true)
			setPropertyToChange(value)
		} else {
			setProperty(value)
		}
	}

	const setProperty = (value) => {
		setSelected({ value })
		setCurrentContract(value.contractID)
	}

	const otherContractsListItemRenderer = ({ option, highlighted }) =>
		option.value === ADD_PROPERTY_LABEL ? (
			<div
				className={classNames(
					"flex items-center cursor-pointer px-3 py-4 border-b-1 border-gray-300",
					highlighted ? "bg-interactive-50" : "bg-white"
				)}
			>
				<Text color="interactive">
					<strong>{ADD_PROPERTY_LABEL}</strong>
				</Text>
			</div>
		) : (
			<div
				className={classNames(
					"flex items-start cursor-pointer px-3 py-4 border-b-1 border-gray-300",
					highlighted ? "bg-interactive-50" : "bg-white"
				)}
			>
				<div className="flex-auto">
					<Text variant="body-short">
						{option.value?.property.streetAddress} {option.value?.property.streetAddress2 ?? ""}
					</Text>
					{getContractNotifications(option.value).map((message, i) => (
						<Text className="text-error-500 flex mt-1">
							<IconWarningTriangleFull className="mr-2" size="20" /> {localizedText(message)}
						</Text>
					))}
				</div>
				<IconTrash
					size="20"
					color="gray"
					onClick={(e) => {
						e.stopPropagation()
						setRemoveProperty(true)
						setPropertyToRemove(option.value)
					}}
				/>
			</div>
		)

	const handleAddressRemoveCancel = (event) => {
		event.preventDefault()

		if (isRemovingProperty) {
			return
		}

		setPropertyToRemove(null)
		setRemoveProperty(false)
	}

	const handleAddressRemoveConfirm = async (event) => {
		event.preventDefault()

		if (isRemovingProperty) {
			return
		}

		setIsRemovingProperty(true)

		await removeContract(propertyToRemove.contractID)

		setIsRemovingProperty(false)

		setPropertyToRemove(null)

		setRemoveProperty(false)
	}

	const handlePropertyChangeConfirm = (event) => {
		event.preventDefault()
		setProperty(propertyToChange)
		hideChangePropertyModal(event)
	}

	const hideChangePropertyModal = (event) => {
		event.preventDefault()
		setPropertyToChange(null)
		setChangeProperty(false)
	}

	const renderRemovalModal = () => {
		return (
			<Dialog
				open={removeProperty}
				onClose={(event) => !isRemovingProperty && handleAddressRemoveCancel(event)}
				header={
					<Text variant="heading-04" className="normal-case">
						{localizedText("ADDRESS_BAR_BDS.REMOVE_PROPERTY_PROMPT", {
							address: propertyToRemove?.property ? getFullStreetAddress(propertyToRemove) : "",
						})}
					</Text>
				}
				modal={true}
				actions={[
					<Button
						className="capitalize"
						variant="filled"
						label={localizedText("ADDRESS_BAR.REMOVE_PROPERTY_BUTTON_YES")}
						size="large"
						loading={isRemovingProperty}
						loadingAnimation="spinner-only"
						onClick={(event) => handleAddressRemoveConfirm(event)}
					/>,
					<Button
						className="capitalize"
						variant="outlined"
						label={localizedText("ADDRESS_BAR.REMOVE_PROPERTY_BUTTON_NO")}
						size="large"
						disabled={isRemovingProperty}
						onClick={(event) => handleAddressRemoveCancel(event)}
					/>,
				]}
			></Dialog>
		)
	}

	const renderChangePropertyModal = () => {
		return (
			<Dialog
				className="max-w-md"
				open={changeProperty}
				onClose={(event) => hideChangePropertyModal(event)}
				header={<Text variant="heading-04" className="normal-case" textTemplateKey="ADDRESS_BAR.CHANGE_PROPERTY_TITLE" />}
				modal={true}
				actionsAlign={isDesktop ? "right" : "center"}
				actions={[
					<div className="flex gap-4 md:gap-y-0 justify-center flex-wrap md:flex-no-wrap">
						<Button
							className="capitalize"
							variant="outlined"
							label={localizedText("ADDRESS_BAR.CHANGE_PROPERTY_BUTTON_CANCEL")}
							size="large"
							onClick={(event) => hideChangePropertyModal(event)}
						/>
						<Button
							className="capitalize"
							variant="filled"
							label={localizedText("ADDRESS_BAR.CHANGE_PROPERTY_BUTTON_YES")}
							size="large"
							onClick={(event) => handlePropertyChangeConfirm(event)}
						/>
					</div>,
				]}
			>
				<Text textTemplateKey="ADDRESS_BAR.CHANGE_PROPERTY_BODY" />
			</Dialog>
		)
	}

	return (
		<>
			{currentContract ? (
				<Select
					size="medium"
					options={otherContracts}
					className="text-interactive"
					selected={selected}
					onSelect={({ value }) => {
						if (value === ADD_PROPERTY_LABEL) {
							history.push("/add-property")
						} else if (!removeProperty) {
							onPropertySelect(value)
						}
					}}
					optionsListItemRenderer={otherContractsListItemRenderer}
				/>
			) : requestServiceContract && !isRequestCurrentContract ? (
				<Input
					size="large"
					inputClassName="text-interactive font-bold"
					inputBorderClassName="border-2 border-interactive"
					value={requestServiceContract.property.streetAddress + " " + requestServiceContract.property.streetAddress2 ?? ""}
					readOnly
				/>
			) : (
				<div className="flex justify-center">
					<ProgressIndicator variant="circular" color="primary" size="medium" />
				</div>
			)}

			{removeProperty && <>{renderRemovalModal()}</>}
			{changeProperty && <>{renderChangePropertyModal()}</>}
		</>
	)
}

const mapStateToProps = (state) => ({
	currentContract: state.contract.current,
	contracts: state.contract.list,
	requestServiceContract: state.requestService.contract,
	isRequestCurrentContract: state.requestService.isRequestCurrentContract(state),
	getContractNotifications: (contract) => state.contract.getContractNotifications(contract),
	getFullStreetAddress: state.contract.getFullStreetAddress,
})

const mapDispatchToProps = (dispatch) => ({
	setCurrentContract: (contractID) => dispatch(ContractActions.setCurrentContract(contractID)),
	removeContract: (contractID) => dispatch(ContractActions.removeContract(contractID)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddressBar)
