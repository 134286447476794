import { useLocalizedText } from "@ftdr/blueprint-components-react"
import React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { getLoginRedirect, isObject, setLoginRedirect, setPageTitle } from "../../utils"
import querystring from "querystring"

const LoginRedirect = (props) => {
	setLoginRedirect()
	const params = querystring.stringify({ redirect: getLoginRedirect() })
	const to = `${props.to}?${params}`

	return <Redirect {...props} to={to} />
}

const PageRoute = ({ route, state }) => {
	let { meta, component: Component, ...rest } = route
	route = { ...rest }

	const localizedText = useLocalizedText()

	React.useEffect(() => {
		if (meta?.title) {
			setPageTitle(localizedText(meta.title))
		}
	}, [])

	if (meta?.validateRoute) {
		let newRoute = null

		if (Array.isArray(meta.validateRoute)) {
			newRoute = meta.validateRoute.reduce((out, validateRoute) => (out ? out : validateRoute(state)), null)
		} else {
			newRoute = meta.validateRoute(state)
		}

		if (newRoute) {
			let rememberPreviousURL = false

			if (isObject(newRoute)) {
				rememberPreviousURL = newRoute.rememberPreviousURL
				newRoute = newRoute.route
			}

			const RedirectComponent = !rememberPreviousURL ? Redirect : LoginRedirect

			Component = () => {
				if (Array.isArray(route.path)) {
					return route.path.map((path) => <RedirectComponent key={path} from={path} to={newRoute} />)
				} else {
					return <RedirectComponent from={route.path} to={newRoute} />
				}
			}
		} else if (meta.componentOverride) {
			let componentOverride = null

			if (Array.isArray(meta.componentOverride)) {
				componentOverride = meta.componentOverride.reduce((out, componentOverride) => (out ? out : componentOverride(state)), null)
			} else {
				componentOverride = meta.componentOverride(state)
			}

			if (componentOverride) {
				Component = componentOverride
			}
		}
	}

	return <Component />
}

const mapStateToProps = (state) => ({
	state: state,
})

export default connect(mapStateToProps)(PageRoute)
