import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import ZestyService from "../../services/zesty"
import HomebaseService from "../../services/homebase"

const actionsBuilder = new ActionsBuilder(namespace)

export const getZestyContentStart = actionsBuilder.createAction("getZestyContentStart", (state, contentKey) => {
	state[namespace][contentKey].isLoading = true
})

export const getZestyContentComplete = actionsBuilder.createAction("getZestyContentComplete", (state, { contentKey, content }) => {
	state[namespace][contentKey].content = content
	state[namespace][contentKey].isLoading = false
	state[namespace][contentKey].hasLoaded = true
})

export const setUserAlerts = actionsBuilder.createAction("setUserAlerts", (state, userAlerts) => {
	state[namespace].userAlerts = userAlerts
})

export const setWhatsCoveredPopupConfigs = actionsBuilder.createAction("setWhatsCoveredPopupConfigs", (state, whatsCoveredPopupConfigs) => {
	state[namespace].whatsCoveredPopupConfigs = whatsCoveredPopupConfigs
})

const createZestyAction = (contentKey, fetchContent) => async (dispatch, getState) => {
	const state = getState()
	if (!(contentKey in state[namespace])) {
		return null
	}

	if (state[namespace][contentKey].hasLoaded) {
		return state[namespace][contentKey].content
	}

	if (state[namespace][contentKey].isLoading) {
		return null
	}

	dispatch(getZestyContentStart(contentKey))

	const content = await fetchContent()

	dispatch(getZestyContentComplete({ contentKey, content }))

	return content
}

export const ZestyActions = {
	getHeader: (siteName) => createZestyAction("header", () => HomebaseService.getHeader(siteName)),
	getFooter: (siteName) => createZestyAction("footer", () => HomebaseService.getFooter(siteName)),
	getTermsAndConditions: (siteName, contentKey) =>
		createZestyAction(contentKey, () => ZestyService.getTermsAndConditions(siteName, contentKey)),

	getUserAlerts: (siteName, environment) => async (dispatch) => {
		try {
			const userAlerts = await ZestyService.getUserAlerts(siteName, environment)

			dispatch(setUserAlerts(userAlerts))

			return userAlerts
		} catch (e) {
			console.log("Failed to get user alerts")
		}

		return []
	},

	getWhatsCoveredPopupConfigs: () => (dispatch) => {
		return ZestyService.getWhatsCoveredPopupConfigs()
			.catch(() => [])
			.then((whatsCoveredPopupConfigs) => {
				dispatch(setWhatsCoveredPopupConfigs(whatsCoveredPopupConfigs))
			})
	},
}

export const actions = actionsBuilder.exportActions()
