import { ActionsBuilder } from "../utils"
import namespace from "./namespace"
import PaymentService from "../../services/payment"
import MyAccountPaymentService from "../../services/my-account/payment"
import { makeCancelable } from "../../utils"
import contractNamespace from "../contract/namespace"
import { addPaymentInfoEnd, addPaymentInfoStart, getPastDueAmountsStart, getPastDueAmountsEnd } from "../contract/actions"

const actionsBuilder = new ActionsBuilder(namespace)

export const fetchPaymentInfoStart = actionsBuilder.createAction("fetchPaymentInfoStart", (state, loadingPromise) => {
	state[namespace].paymentInfo = null
	state[namespace].paymentInfoLoading = true
	state[namespace].paymentInfoLoadingPromise = loadingPromise

	state[namespace].paymentInfoError = false
})

export const fetchPaymentInfoEnd = actionsBuilder.createAction("fetchPaymentInfoEnd", (state, paymentInfo) => {
	if (paymentInfo) {
		state[namespace].paymentInfo = paymentInfo
	}

	state[namespace].paymentInfoError = !paymentInfo

	state[namespace].paymentInfoLoading = false
	state[namespace].paymentInfoLoadingPromise = null
})

export const fetchPastDueAmountsStart = actionsBuilder.createAction("fetchPastDueAmountsStart", (state, loadingPromise) => {
	state[namespace].pastDueAmounts = null
	state[namespace].pastDueAmountsLoading = true
	state[namespace].pastDueAmountsLoadingPromise = loadingPromise

	state[namespace].pastDueAmountsError = false
})

export const fetchPastDueAmountsEnd = actionsBuilder.createAction("fetchPastDueAmountsEnd", (state, pastDueAmounts) => {
	if (pastDueAmounts) {
		state[namespace].pastDueAmounts = pastDueAmounts
	}

	state[namespace].pastDueAmountsError = !pastDueAmounts

	state[namespace].pastDueAmountsLoading = false
	state[namespace].pastDueAmountsLoadingPromise = null
})

export const PaymentActions = {
	fetchPaymentInfo: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]
		const { paymentInfoLoadingPromise } = state[namespace]

		if (paymentInfoLoadingPromise) {
			paymentInfoLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(PaymentService.fetchPaymentInfo(current.contractID))
		dispatch(fetchPaymentInfoStart(cancelablePromise))
		dispatch(addPaymentInfoStart({ loadingPromise: cancelablePromise, contractID: current.contractID }))

		try {
			const paymentInfo = await cancelablePromise.promise
			dispatch(fetchPaymentInfoEnd(paymentInfo))
			dispatch(addPaymentInfoEnd({ paymentInfo, contractID: current.contractID }))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchPaymentInfoEnd())
				dispatch(addPaymentInfoEnd({ contractID: current.contractID }))
			}
		}
	},
	setPaymentInfoFromCurrentContract: () => (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]

		if (!current) {
			return
		}

		dispatch(fetchPaymentInfoEnd(current.paymentInfo))
	},
	fetchPastDueAmounts: () => async (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]
		const { pastDueAmountsLoadingPromise } = state[namespace]

		if (pastDueAmountsLoadingPromise) {
			pastDueAmountsLoadingPromise.cancel()
		}

		if (!current) {
			return
		}

		const cancelablePromise = makeCancelable(MyAccountPaymentService.getPastDueAmounts(current.contractID))
		dispatch(fetchPastDueAmountsStart(cancelablePromise))
		dispatch(getPastDueAmountsStart({ loadingPromise: cancelablePromise, contractID: current.contractID }))

		try {
			const pastDueAmounts = await cancelablePromise.promise
			dispatch(fetchPastDueAmountsEnd(pastDueAmounts))
			dispatch(getPastDueAmountsEnd({ pastDueAmounts, contractID: current.contractID }))
		} catch (e) {
			if (!e.canceled) {
				dispatch(fetchPastDueAmountsEnd())
				dispatch(getPastDueAmountsEnd({ contractID: current.contractID }))
			}
		}
	},
	setPastDueAmountsFromCurrentContract: () => (dispatch, getState) => {
		const state = getState()
		const { current } = state[contractNamespace]

		if (!current) {
			return
		}

		dispatch(fetchPastDueAmountsEnd(current.pastDueAmounts))
	},
}

export const actions = actionsBuilder.exportActions()
