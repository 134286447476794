import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { compose } from "redux"
import Alert from "./Alert"
import { AlertActions } from "../store/alert/actions"
import { usePilotGroup } from "../hooks"

const canShowUserAlert = ({ userAlert, userAlerts, isInPilotGroup, suppressedPromos, canRequestService, location }) => {
	if (userAlert.pilotGroupType && !isInPilotGroup) return false
	if (userAlert.suppressible && suppressedPromos.includes(userAlert.promo)) return false
	if (userAlert.serviceItemID && !canRequestService) return false

	if (userAlert.showOnLogin) {
		return true
	}

	// If this is not shown on login and there are other alerts shown on login, then don't show this alert
	if (userAlerts.some((userAlert) => userAlert.showOnLogin)) return false

	if (userAlert.showOnAllPages) {
		return true
	}

	if (userAlert.showOnDashboard && location.pathname.startsWith("/dashboard")) {
		return true
	}

	if (
		userAlert.showOnRequestService &&
		location.pathname.startsWith("/request-service") &&
		!location.pathname.startsWith("/request-service-")
	) {
		return true
	}

	return false
}

const UserAlert = ({
	userAlert,
	isAboveHeader,
	history,
	location,
	userAlerts,
	contractID,
	sessionUUID,
	canRequestService,
	suppressPromo,
	suppressedPromos,
}) => {
	const { isInPilotGroup } = usePilotGroup(userAlert.pilotGroupType, contractID, sessionUUID)

	if (!canShowUserAlert({ userAlert, userAlerts, isInPilotGroup, suppressedPromos, canRequestService, isAboveHeader, location })) {
		return null
	}

	if (userAlert.suppressible && !userAlert.promo) {
		return (
			<Alert
				alert={{
					id: userAlert.id,
					options: {
						useBDS: true,
						variant: isAboveHeader ? "inline" : "floating",
						type: "warning",
						message: `User alert defined as suppressible without a promo field! Please enter promo field for user alert: ${userAlert.message}`,
					},
				}}
			/>
		)
	} else {
		return (
			<Alert
				alert={{
					id: userAlert.id,
					options: {
						id: userAlert.id,
						useBDS: true,
						variant: isAboveHeader ? "inline" : "floating",
						type: userAlert.alertType,
						message: userAlert.message,
						closeButton: userAlert.suppressible && userAlert.promo,
						onCloseAlert: () => suppressPromo(userAlert.promo),
						button: userAlert.serviceItemID &&
							canRequestService && {
								text: userAlert.buttonLabel,
								onClick: (event) => {
									event.preventDefault()

									history.push("/request-service", {
										recallItem: {
											serviceItemID: userAlert.serviceItemID,
										},
									})
								},
							},
					},
				}}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	userAlerts: state.zesty.userAlerts,
	contractID: state.contract.current?.contractID,
	sessionUUID: state.requestService.sessionUUID,
	canRequestService: state.requestService.canRequestService,
	suppressedPromos:
		state.user.profile?.contractCustomer.find((contractCustomer) => contractCustomer.contractID === state.contract.current?.contractID)
			?.suppressedPromos ?? [],
})

const mapDispatchToProps = (dispatch) => ({
	suppressPromo: (alert) => dispatch(AlertActions.suppressPromo(alert)),
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UserAlert)
