export default {
	paymentInfoLoading: false,
	paymentInfoLoadingPromise: null,
	paymentInfoError: false,
	paymentInfo: null,

	pastDueAmountsLoading: false,
	pastDueAmountsLoadingPromise: null,
	pastDueAmountsError: false,
	pastDueAmounts: null,

	hasNextAutoPayment() {
		return (
			this.paymentInfo &&
			this.paymentInfo.automaticPayment &&
			this.paymentInfo.automaticPayment.paymentDate !== "" &&
			this.paymentInfo.automaticPayment.amount > 0
		)
	},

	hasSavedPaymentMethod() {
		return this.paymentInfo && this.paymentInfo.methods && this.paymentInfo.methods.length > 0
	},
}
