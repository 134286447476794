import { useEffect, useState } from "react"
import ConfigService from "../services/config"
import Loading from "../pages/Loading"

const ConfigLoader = ({ children }) => {
	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		ConfigService.loadConfig().then(() => {
			setLoading(false)
		})
	}, [])

	return isLoading ? <Loading /> : <>{children}</>
}

export default ConfigLoader
