import ConfigService from "../services/config"

export default class QuiqChat {
	static hasAddedScript = false
	static loading = false
	static hasInitialized = false
	static contactPoints = null
	static chatClient = null
	static queuedRequests = []
	static tenant = ""
	static active = false

	static async initialize(contactPoint, options) {
		if (window.Quiq === undefined || this.loading || contactPoint === "") {
			return null
		}
		this.loading = true
		if (this.chatClient === null) {
			this.chatClient = window.Quiq({
				contactPoint: contactPoint,
				...options,
			})
		}
		this.loading = false
		this.hasInitialized = true
		return this.chatClient
	}

	static async reinitialize(contactPoint, options) {
		if (this.chatClient === null) {
			throw new Error("Chat client is not initialized")
		}

		if (this.loading) {
			return new Promise((resolve, reject) => {
				this.queuedRequests.push({
					request: { contactPoint, options },
					resolve,
					reject,
				})
			})
		}

		this.loading = true

		try {
			await this.chatClient.reinitialize({ contactPoint, ...options })
		} catch (e) {
			this.loading = false
			if (!this.queuedRequests.length) {
				throw e
			}
		}

		this.loading = false

		if (this.queuedRequests.length) {
			// get the most recent request to process
			const { request, resolve, reject } = this.queuedRequests.pop()

			// remove the rest of the pending requests and tell them they have been rejected
			this.queuedRequests.splice(0, this.queuedRequests.length).forEach(({ reject }) => reject())

			// process the most recent request
			this.reinitialize(request).then(resolve).catch(reject)
		}

		return this.chatClient
	}

	static loadConfig() {
		const config = ConfigService.config.quiqChat

		this.contactPoints = config.contactPoints
		this.tenant = config.tenant
	}

	static addScript() {
		if (this.hasAddedScript) return
		this.hasAddedScript = true

		const script = document.createElement("script")
		const protocol = "https://"
		script.type = "text/javascript"
		script.src = `${protocol}${this.tenant}.quiq-api.com/app/webchat/index.js`

		document.head.appendChild(script)
	}

	static async unload(clearSession) {
		if (this.chatClient !== null) {
			const response = await this.chatClient.unload(clearSession)
			this.chatClient = null
			this.hasInitialized = false
			return response
		}
		return false
	}

	static populateWelcomeForm(firstName, lastName, contractID, address, dispatchID) {
		if (this.loading || !this.hasInitialized || !this.chatClient) {
			return
		}
		this.chatClient.setChatRegistrationField("schema.conversation.customer.firstName", firstName)
		this.chatClient.setChatRegistrationField("schema.conversation.customer.lastName", lastName)
		//contractID1 is intentional.
		this.chatClient.setChatRegistrationField("schema.conversation.customer.custom.contractID1", contractID)
		this.chatClient.setChatRegistrationField("schema.conversation.customer.custom.address1", address)
		this.chatClient.setChatRegistrationField("schema.conversation.customer.custom.dispatchID", dispatchID)
	}

	static async getChatStatus() {
		let data
		try {
			data = await this.chatClient.getChatStatus()
		} catch (e) {
			throw e
		}

		if (data) {
			this.active = data.active
		}
		return this.active
	}
}
