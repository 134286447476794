import React from "react"
import AddressBar from "./AddressBar"
import { AppContext, Breadcrumbs, Text } from "@ftdr/blueprint-components-react"
import classNames from "classnames"

class PageHeader extends React.Component {
	static contextType = AppContext

	render() {
		if (!this.props.title && !this.props.showAddressSelector) {
			return null
		}

		return (
			<div id="page-content-header" className="page-content-header xl:px-0 py-2 px-4">
				<div className="container mx-auto pt-2">
					{Boolean(this.props.breadcrumbs) && (
						<div className="mb-6">
							<Breadcrumbs {...this.props.breadcrumbs} showBackButtonOnMobile={false} />
						</div>
					)}

					<div className="md:grid gap-6 grid-cols-3">
						<div
							className={classNames({
								"col-span-2": this.props.showAddressSelector,
								"col-span-1": !this.props.showAddressSelector && this.props.sideContent,
								"col-span-3": !this.props.showAddressSelector && !this.props.sideContent,
							})}
						>
							{this.props.title && (
								<>
									<Text
										variant="heading-03"
										color="primary"
										className={classNames("leading-7", { "mb-1": this.props.subTitle })}
									>
										{this.props.title}
									</Text>
									<Text variant="body-short" color="primary">
										{this.props.subTitle}
									</Text>
								</>
							)}
						</div>
						{this.props.showAddressSelector && (
							<div className="mt-4 md:mt-0">
								<AddressBar />
							</div>
						)}
						{Boolean(this.props.sideContent) && (
							<div className="self-center mt-4 md:mt-0 col-span-2">{this.props.sideContent}</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default PageHeader
