import { useEffect } from "react"
import { FeatureToggleNames } from "../contexts/FeatureToggles/constants"
import { useFeatureToggleOn } from "../contexts/FeatureToggles/utils"
import QuiqChat from "../lib/quiqchat"

const QuiqChatLoader = ({ children }) => {
	const [, isEnabled] = useFeatureToggleOn(FeatureToggleNames.QUIQCHAT)

	useEffect(() => {
		QuiqChat.loadConfig()
	}, [])

	useEffect(() => {
		if (isEnabled) {
			QuiqChat.addScript()
		}
	}, [isEnabled])

	return <>{children}</>
}

export default QuiqChatLoader
