import GenericService from "../generic"
import { MyaccountJSClient, myaccountpb } from "@ftdr/myaccount-js-client"
import { ProtobufHttpClient, TokenHandlingHttpClient } from "@ftdr/http-utils"
import axios from "axios"

const SERVICE_NAME = "myaccount"

export class MyAccountService extends GenericService {
	constructor() {
		super(SERVICE_NAME)
	}

	async getClient(config = undefined, options = { checkValidateContractAccessError: true }) {
		const clientConfig = this.injectServiceConfig({ config, isMyAccount: true })
		const client = axios.create(clientConfig)
		client.interceptors.request.use((config) => {
			// Fix GET requests dropping the Content-Type header because request body is undefined.
			// Setting a null value will allow the Content-Type header to be sent.
			// This will allow the backend to properly parse the proto_body query param as protobuf instead of defaulting to JSON.
			config.data = config.data || null
			return config
		})
		if (options?.checkValidateContractAccessError) {
			client.interceptors.response.use(null, (error) => {
				if (error.isAxiosError && error.response?.data) {
					try {
						const decoded = myaccountpb.MyAccountErrorResponse.decode(new Uint8Array(error.response?.data))
						this.checkValidateContractAccessErrorInResponse(decoded)
					} catch (err) {
						console.error("Error decoding MyAccountErrorResponse from MyaccountJSClient error")
					}
				}

				return Promise.reject(this.handleError(error))
			})
		} else {
			client.interceptors.response.use(null, (error) => {
				return Promise.reject(this.handleError(error))
			})
		}
		const { token } = await this.getAccessToken()
		const tokenHandlingClient = new TokenHandlingHttpClient(token, undefined, client)
		const protobufClient = new ProtobufHttpClient(tokenHandlingClient)

		return new MyaccountJSClient(clientConfig.baseURL, protobufClient)
	}
}

export default new MyAccountService()
