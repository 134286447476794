import React, { useContext, useEffect, useMemo, useState } from "react"
import { FeatureTogglesContext } from "./Context"
import { useSelector } from "react-redux"
import { getSiteName } from "../../utils"
import { FEATURE_TOGGLE_DEFAULT_VALUE } from "./constants"
import AuthService from "../../services/auth"
import jwtDecode from "jwt-decode"

export const useFeatureToggleList = () => {
	const { getFeatureToggleList } = useContext(FeatureTogglesContext)

	return getFeatureToggleList()
}

export const useUserDataForFeatureToggle = (attributes) => {
	const isLoggedIn = useSelector((state) => state?.user?.isLoggedIn)
	const [userID, setUserID] = useState()

	useEffect(() => {
		let cancelled = false

		AuthService.getAccessToken().then(({ token, isGuestToken }) => {
			if (cancelled) return

			const claims = jwtDecode(token)
			setUserID(isGuestToken ? claims?.jti : claims?.sub)
		})

		return () => {
			cancelled = true
		}
	}, [])

	const tenant = getSiteName()
	const userRoleType = isLoggedIn ? "customer" : "guest"

	const userAttributes = useMemo(
		() => ({
			tenant,
			user_role_type: userRoleType,
			...attributes,
		}),
		[tenant, userRoleType, attributes]
	)

	return { userID, userAttributes }
}

export const useFeatureToggle = (featureToggleName, attributes) => {
	const { getFeatureToggle } = useContext(FeatureTogglesContext)
	const { userID, userAttributes } = useUserDataForFeatureToggle(attributes)
	const [ready, setReady] = useState(false)
	const [value, setValue] = useState(FEATURE_TOGGLE_DEFAULT_VALUE)

	useEffect(() => {
		if (userID) {
			setValue(getFeatureToggle(userID, featureToggleName, userAttributes))
		}
		setReady(Boolean(userID))
	}, [featureToggleName, userID, userAttributes])

	// Default to the function call if provider has no hooks
	// This will be a one-time check and does not react
	return [ready, value]
}

export const useFeatureToggleOn = (featureToggleName, attributes) => {
	const [ready, value] = useFeatureToggle(featureToggleName, attributes)
	return [ready, value === "on"]
}

export const withFeatureToggleValue = (featureToggleName, attributes, propName) => (Component) =>
	React.forwardRef((props, ref) => {
		const [ready, value] = useFeatureToggle(featureToggleName, attributes)

		const propValue = useMemo(() => ({ ready, value }), [ready, value])

		const mergedProps = {
			ref,
			...props,
			[propName]: propValue,
		}

		return <Component {...mergedProps} />
	})

export const withFeatureToggleOn = (featureToggleName, attributes, propName) => (Component) =>
	React.forwardRef((props, ref) => {
		const [ready, value] = useFeatureToggle(featureToggleName, attributes)

		const propValue = useMemo(() => ({ ready, value: value === "on" }), [ready, value])

		const mergedProps = {
			ref,
			...props,
			[propName]: propValue,
		}

		return <Component {...mergedProps} />
	})

export const withFeatureToggleCheck = (featureToggleName, attributes, condition) => (Component) =>
	React.forwardRef((props, ref) => {
		const [, value] = useFeatureToggle(featureToggleName, attributes)

		const matches = useMemo(() => {
			if (typeof condition === "function") {
				return condition(value)
			} else if (condition === undefined) {
				return value === "on"
			} else {
				return value === condition
			}
		}, [value, condition])

		return matches ? <Component ref={ref} {...props} /> : null
	})

export const withFeatureTogglesContext = (Component) =>
	React.forwardRef((props, ref) => (
		<FeatureTogglesContext.Consumer>
			{(context) => (context.isReady ? <Component ref={ref} {...props} featureTogglesContext={context} /> : null)}
		</FeatureTogglesContext.Consumer>
	))
